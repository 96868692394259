import { Portal } from '@air/primitive-portal';
import { tailwindVariants } from '@air/tailwind-variants';
import { useWindowSize } from '@react-hook/window-size';
import { animated, useSpring } from '@react-spring/web';
import { useDrag } from '@use-gesture/react';
import { memo, type ReactNode, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { WORKSPACE_ACTIVITY_CONTAINER_CORNER } from '~/constants/localStorageKeys';
import { useFileTrackingPaneBottomPos } from '~/hooks/useFileTrackingPaneBottomPos';
import { isImportRouteSelector } from '~/store/router/selectors';

const workspaceActivityContainer = tailwindVariants({
  base: 'pointer-events-none absolute flex max-h-screen select-none flex-col gap-2 overflow-y-auto p-4',
  variants: {
    corner: {
      topLeft: 'left-0 top-0 flex-col-reverse',
      topRight: 'right-0 top-0 flex-col-reverse items-end',
      bottomLeft: 'bottom-0 left-0',
      bottomRight: 'bottom-0 right-0 items-end',
    },
  },
});

const workspaceActivityContainerContent = tailwindVariants({
  base: 'pointer-events-auto flex flex-col gap-2',
  variants: {
    corner: {
      topLeft: 'flex-col-reverse',
      topRight: 'flex-col-reverse',
      bottomLeft: '',
      bottomRight: 'items-end',
    },
  },
});

const workspaceActivityContainerHighlight = tailwindVariants({
  base: 'pointer-events-none fixed h-1/2 w-1/2 bg-indigo-4 opacity-50',
  variants: {
    corner: {
      topLeft: 'left-0 top-0',
      topRight: 'right-0 top-0',
      bottomLeft: 'bottom-0 left-0',
      bottomRight: 'bottom-0 right-0',
    },
  },
});

export type WorkspaceActivityContainerCorner = 'topLeft' | 'topRight' | 'bottomLeft' | 'bottomRight';

export type WorkspaceActivityContainerProps = {
  children: ReactNode;
};

export const WorkspaceActivityContainer = memo(({ children }: WorkspaceActivityContainerProps) => {
  const isImportRoute = useSelector(isImportRouteSelector);
  const [corner, setCorner] = useState<WorkspaceActivityContainerCorner>('bottomRight');
  const [highlightCorner, setHighlightCorner] = useState<WorkspaceActivityContainerCorner | null>(null);
  const [{ x, y }, api] = useSpring(() => ({ x: 0, y: 0 }));
  const [windowWidth, windowHeight] = useWindowSize({
    initialWidth: typeof window === 'undefined' ? 0 : window.innerWidth,
    initialHeight: typeof window === 'undefined' ? 0 : window.innerHeight,
    wait: 100,
  });
  const { bottomPos, uploaderRef } = useFileTrackingPaneBottomPos();

  const onUpdateCorner = useCallback((corner: WorkspaceActivityContainerCorner) => {
    setCorner(corner);
    localStorage.setItem(WORKSPACE_ACTIVITY_CONTAINER_CORNER, corner);
  }, []);

  const bind = useDrag(({ last, movement: [mx, my], xy: [x, y] }) => {
    const getCorner = () => {
      const isLeft = x < windowWidth / 2;
      const isTop = y < windowHeight / 2;
      if (isTop) return isLeft ? 'topLeft' : 'topRight';
      return isLeft ? 'bottomLeft' : 'bottomRight';
    };

    const corner = getCorner();

    if (last) {
      onUpdateCorner(corner);
      api.start({ x: 0, y: 0, immediate: true });
      setHighlightCorner(null);
    } else {
      /**
       * Ensure that the highlight corner is only set when the user is dragging the container.
       */
      if (mx || my) {
        setHighlightCorner(corner);
      }
      api.start({ x: mx, y: my, immediate: true });
    }
  });

  useEffect(() => {
    const localStorageCorner = localStorage.getItem(
      WORKSPACE_ACTIVITY_CONTAINER_CORNER,
    ) as WorkspaceActivityContainerCorner | null;

    if (localStorageCorner) {
      onUpdateCorner(localStorageCorner);
    }
  }, [onUpdateCorner]);

  return (
    <Portal>
      {highlightCorner && <div className={workspaceActivityContainerHighlight({ corner: highlightCorner })} />}
      <animated.div
        className={workspaceActivityContainer({ corner })}
        data-draggable={false}
        {...bind()}
        style={{
          x,
          y,
          zIndex: isImportRoute ? -1 : 1,
          /**
           * We need to use the `bottomPos` value when the snap location is on the bottom right because we have a few things
           * that can overlap the workspace activity container, like the selection bar and the intercom launcher.
           */
          bottom: corner === 'bottomRight' ? bottomPos : undefined,
        }}
      >
        <animated.div className={workspaceActivityContainerContent({ corner })} ref={uploaderRef}>
          {children}
        </animated.div>
      </animated.div>
    </Portal>
  );
});

WorkspaceActivityContainer.displayName = 'WorkspaceActivityContainer';
