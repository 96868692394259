import { useIsLoggedIn } from '@air/utils-auth';
import Router from 'next/router';
import { useEffect } from 'react';

import { Routes } from '~/constants/routes';

/**
 * Hook used to redirect unauthenticated users to the login page on pages
 * that doesn't make an API requests. If the page makes an API request, use
 * `useHandleUnauthenticatedUser` instead since we have a lisetner that
 * triggers much faster than this hook.
 */
export const useRedirectUnauthenticatedUser = () => {
  const { isLoggedIn, isLoggedInFetched } = useIsLoggedIn();

  useEffect(() => {
    if (isLoggedInFetched && !isLoggedIn) {
      Router.ready(() => {
        Router.push(Routes.auth.login, {
          query: {
            redirect: encodeURIComponent(Router.asPath),
          },
        });
      });
    }
  }, [isLoggedInFetched, isLoggedIn]);
};
